.menu-screen {
  position: relative;
  width   : 100%;
  z-index : 999;
  transition: all 2s ease;
  background-color: #cdcdcdcb;
}

.container {
  display         : flex;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  height          : 100%;
  width           : 100%;
}

.title {
  font-size : 3rem;
  text-align: center;
  width     : 100%;
}

.start-button {
  padding      : 8px;
  border       : 3px solid #a611ff;
  transition   : all .5s ease;
  color        : #222;
  border-radius: 8px;
  font-size    : 1.45rem;
}

.start-button:hover {
  transform : scale(1.1);
  box-shadow: 2px 2px 8px #000;
}