$cardShape: polygon(16.5% 0%,
    83.5% 0%,
    100% 15%,
    100% 85%,
    83.5% 100%,
    16.5% 100%,
    0% 85%,
    0% 15%);

$box-pixel: (
  1px 1px #000,
  9px 5px #000000,
  10px 5px #000000,
  11px 5px #000000,
  12px 5px #000000,
  13px 5px #000000,
  14px 5px #000000,
  15px 5px #000000,
  16px 5px #000000,
  17px 5px #000000,
  8px 6px #000000,
  18px 6px #000000,
  7px 7px #000000,
  19px 7px #000000,
  7px 8px #000000,
  19px 8px #000000,
  7px 9px #000000,
  19px 9px #000000,
  7px 10px #000000,
  19px 10px #000000,
  7px 11px #000000,
  19px 11px #000000,
  7px 12px #000000,
  19px 12px #000000,
  7px 13px #000000,
  19px 13px #000000,
  7px 14px #000000,
  19px 14px #000000,
  7px 15px #000000,
  19px 15px #000000,
  8px 16px #000000,
  18px 16px #000000,
  9px 17px #000000,
  10px 17px #000000,
  11px 17px #000000,
  12px 17px #000000,
  13px 17px #000000,
  14px 17px #000000,
  15px 17px #000000,
  16px 17px #000000,
  17px 17px #000000
);

.card {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 2rem;
  width: 40px;
  text-align: center;
  margin: 5px;
  transition: 0.1s ease;
  position: relative;
  box-shadow: 1px 1px #000, -1px 1px #000, -1px -1px #000, 1px -1px #000, 2px 0px #000, -2px 0px #000, 1px -2px #000, 2px 2px 0px 1px #000;
  // -webkit-clip-path: $cardShape;
  // clip-path: $cardShape;
}

.card:hover {
  background-color: #0000007b;
  box-shadow: inset 1px #000, inset -1px 1px #000, inset -1px -1px #000, inset 1px -1px #000, inset 2px 0px #000, inset -2px 0px #000, inset 1px -2px #000, inset 2px 2px 0px 1px #000;
}

.card-container {
  display: inline-block;
  text-align: center;
}

.pixel-art {
  width: 2px;
  height: 2px;
  background: transparent;
  scale: 2;
  box-shadow: ($box-pixel);
}