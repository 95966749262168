.container{
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
}

.box{
  padding: 100px 50px;
  background-color: #cdcdcdcb;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: relative;
}

.close-modal-container{
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.close-modal{
  font-size: 1.45rem;
  cursor: pointer;
  padding: 4px;
  border: 1px solid #000;
}
