.points-counter,
.munition-info {
  color: #15f419;
  font-family: monospace;
  text-transform: uppercase;
  width: fit-content;
  display: inline;
  font-size: 2rem;
  padding: 10px;
  z-index: 99;
}


.life-counter{
  color:'#FF3131 !important';
  text-transform: uppercase;
  width: fit-content;
  display: inline;
  font-size: 2rem;
  padding: 10px;
  z-index: 99;
}

.munition-info {
  width: fit-content;
  display: inline;
  bottom: 0;
  right: 0;
  text-align: center;
}

.munition-reload {
  font-size: 2.5rem;
  display: inline;
}

.munitions-counter {
  width: 100%;
  display: inline;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.munition {
  width: 20px;
  display: inline;
  height: 10px;
  margin: 8px 3px;
  background-color: #15f419;
  color: transparent;
}

.life-counter {
  right: 0;
  color:'red'
}

.glass {
  width: 100%;
  height: 100%;
  font-size: 0px;
  z-index: 10;
  top: 0px;
}
