.container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../gameAssets/images/backgrounds/space8.gif');
}

.main{
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #000000a6;
  color: #04dc12;
}

.game-over-text{
  font-size: 5rem;
  text-transform: uppercase;
  width: 100%;
}

.score-text{
  width: 100%;
  font-size: 1.25rem;
}

.info-text{
  margin-top: 40px;
}