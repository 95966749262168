.container{
  max-width: 100%;
  width: 50%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.space-ship-image-container{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
}

.space-ship-image {
  width: 30vw;
  min-width: 300px;
  min-height: fit-content;
  max-width: fit-content;
}

.space-ship-title,
.space-ship-description {
  width: 90%;
  text-align: center;
}

.space-ship-title {
  font-size: 2.15rem;
}

.space-ship-description {
  font-size: 1.15rem;
}
