.level-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  transition: all ease-in-out .5s;
}

.level-box {
  width: 100%;
  min-height: 60vh;
  position: absolute;
  top: calc(50% - 200px);
  left: -100vw;
  background-color: rgba(240, 240, 240, 0.836);
  text-align: center;
  transition: all ease-in-out .5s;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-evenly;
  border-radius: 15px;
  color: #108612;
  box-sizing: border-box;
}

.centered-x{
  left: 3vw;
}

.out-right{
  left: 100vw;
}

.text{
  font-size: 4.2rem;
  padding: 3px 8px;
  border: 2px solid #a3b6aa;
  width: 80%;
}

.level-num{
  width: 100%;
  font-size: 13rem;
}