
.App{
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}



@-webkit-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
  }
  @-ms-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
  }
  @-o-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
  }
  @keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
  }
  
  @-webkit-keyframes rainbowbg {
    0% {background: #ff0000;}
    10% {background: #ff8000;}
    20% {background: #ffff00;}
    30% {background: #80ff00;}
    40% {background: #00ff00;}
    50% {background: #00ff80;}
    60% {background: #00ffff;}
    70% {background: #0080ff;}
    80% {background: #0000ff;}
    90% {background: #8000ff;}
    100% {background: #ff0080;}
    }
    @-ms-keyframes rainbow {
    0% {background: #ff0000;}
    10% {background: #ff8000;}
    20% {background: #ffff00;}
    30% {background: #80ff00;}
    40% {background: #00ff00;}
    50% {background: #00ff80;}
    60% {background: #00ffff;}
    70% {background: #0080ff;}
    80% {background: #0000ff;}
    90% {background: #8000ff;}
    100% {background: #ff0080;}
    }
    @-o-keyframes rainbowbg {
    0% {background: #ff0000;}
    10% {background: #ff8000;}
    20% {background: #ffff00;}
    30% {background: #80ff00;}
    40% {background: #00ff00;}
    50% {background: #00ff80;}
    60% {background: #00ffff;}
    70% {background: #0080ff;}
    80% {background: #0000ff;}
    90% {background: #8000ff;}
    100% {background: #ff0080;}
    }
    @keyframes rainbowbg {
    0% {background: #ff0000;}
    10% {background: #ff8000;}
    20% {background: #ffff00;}
    30% {background: #80ff00;}
    40% {background: #00ff00;}
    50% {background: #00ff80;}
    60% {background: #00ffff;}
    70% {background: #0080ff;}
    80% {background: #0000ff;}
    90% {background: #8000ff;}
    100% {background: #ff0080;}
    }

    
    @keyframes tilt-n-move-shaking {
      0% { transform: translate(0, 0) rotate(0deg); }
      25% { transform: translate(5px, 5px) rotate(5deg); }
      50% { transform: translate(0, 0) rotate(0eg); }
      75% { transform: translate(-5px, 5px) rotate(-5deg); }
      100% { transform: translate(0, 0) rotate(0deg); }
    }

    .facemove{
      -webkit-animation:tilt-n-move-shaking 10s infinite;
      -ms-animation:tilt-n-move-shaking 5s infinite;
      -o-animation:tilt-n-move-shaking 5s infinite;
      animation:tilt-n-move-shaking 5s infinite;
      }
      

.epilepsy{
-webkit-animation:rainbow .1s infinite;
-ms-animation:rainbow .5s infinite;
-o-animation:rainbow .5s infinite;
animation:rainbow .5s infinite;
}
